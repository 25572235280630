import React from "react";
import roles from "variables/roles";
import states from "variables/states";
import { NavLink, useHistory } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { default as ReactSelect } from "react-select";
import InputMask from 'react-input-mask';

import { useCreateUser } from "hooks/users";
import { useUnits } from "hooks/units";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import { msDarkTheme, msLightTheme } from "variables/multiselectStyle";

const schema = yup.object().shape({
  name: yup.string().required('O campo "Nome" é obrigatório'),
  email: yup
    .string()
    .email("Insira um e-mail válido")
    .required("O campo 'E-mail' é obrigatório"),
  password: yup
    .string()
    .matches(
        /(?=.*[A-Za-z])(?=.*[0-9])/g, "Insira um senha forte, contendo no mínimo uma letra e um número"
    )
    .required('O campo "senha" é obrigatório'),
  password_verification: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas não coincidem")
    .required('O campo "Confirmar senha" é obrigatório'),
  role: yup.object().required('O campo "Perfil" é obrigatório'),
});

const CreateUser = () => {
  const methods = useForm();
  const toast = useToast();
  const { units } = useUnits();
  const { colorMode } = useColorMode();
  const history = useHistory();
  const selectRoles = [
    { value: roles.doctor, label: "Médico" },
    { value: roles.partner, label: "Usuário" },
  ];
  const selectStates = states.map((i) => ({ value: i.id, label: i.initials }));

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });
  const watchRole = watch("role", null); // escuta o campo role e as mudanças dele
  const { mutateAsync: data, isLoading } = useCreateUser({
    onSuccess: () => {
      reset();
      toast({
        title: "Usuário criado com sucesso.",
        status: "success",
        duration: 5000,
        isClosable: true,
      }),
        history.push(`/sistema/administracao/usuarios`);
    },
    onError: (err) => {
      Object.keys(err.response.data).forEach((i) => {
        toast({
          title: err.response.data[i].join(", "),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    },
  });

  const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
  const inputHover = useColorModeValue("healthlab.800", "healthlab.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Flex direction="column">
            <Text color={textColor} fontSize="lg" fontWeight="bold" mb="3px">
              Criar Usuário
            </Text>
            <Text color="gray.400" fontWeight="normal" fontSize="sm">
              Preencha dos campos para realizar o cadastro
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <FormProvider {...methods}>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(data)}>
              <Flex direction="column" w="100%">
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                  gap="24px"
                >
                  <FormControl isInvalid={errors.role}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Perfil
                    </FormLabel>
                    <Controller
                      name="role"
                      {...register("role")}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactSelect
                          name="role"
                          options={selectRoles}
                          isClearable
                          backspaceRemovesValue={true}
                          placeholder="Selecione"
                          onBlur={onBlur}
                          onChange={onChange}
                          selected={value}
                          styles={
                            colorMode === "light" ? msLightTheme : msDarkTheme
                          }
                        />
                      )}
                    />
                    {errors.role && (
                      <FormErrorMessage>{errors.role.message}</FormErrorMessage>
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                  gap="24px"
                  mt="24px"
                >
                  <FormControl isInvalid={errors.name}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Nome
                    </FormLabel>
                    <Input
                      {...register("name")}
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.name && (
                      <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.email}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      E-mail
                    </FormLabel>
                    <Input
                      {...register("email")}
                      focusBorderColor={inputHover}
                      type="email"
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.email && (
                      <FormErrorMessage>
                        {errors.email.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Grid>

                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                  gap="24px"
                  mt="24px"
                >
                  <FormControl isInvalid={errors.password}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Senha
                    </FormLabel>
                    <Input
                      {...register("password")}
                      focusBorderColor={inputHover}
                      type="password"
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.password && (
                      <FormErrorMessage>
                        {errors.password.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={errors.password_verification}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Confirmar senha
                    </FormLabel>
                    <Input
                      {...register("password_verification")}
                      focusBorderColor={inputHover}
                      type="password"
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.password_verification && (
                      <FormErrorMessage>
                        {errors.password_verification.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Grid>

                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                  gap="24px"
                  mt="24px"
                >
                  <FormControl isInvalid={errors.unit}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Unidade
                    </FormLabel>
                    <Controller
                      name="unit"
                      {...register("unit")}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ReactSelect
                          name="unit"
                          options={units.map((i) => ({
                            value: i.id,
                            label: i.name,
                          }))}
                          isSearchable
                          isClearable
                          isMulti
                          backspaceRemovesValue={true}
                          placeholder="Selecione"
                          onBlur={onBlur}
                          onChange={onChange}
                          selected={value}
                          styles={
                            colorMode === "light" ? msLightTheme : msDarkTheme
                          }
                        />
                      )}
                    />
                    {errors.unit && (
                      <FormErrorMessage>{errors.unit.message}</FormErrorMessage>
                    )}
                  </FormControl>
                </Grid>
                {!!watchRole &&
                  !!watchRole.value &&
                  watchRole.value === roles.doctor && (
                    <Grid
                      templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                      gap="24px"
                      mt="24px"
                    >
                      <FormControl isInvalid={errors.crm_number}>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          CRM Número
                        </FormLabel>
                        <Input
                          {...register("crm_number")}
                          focusBorderColor={inputHover}
                          type="number"
                          borderRadius="8px"
                          fontSize="md"
                        />
                        {errors.crm_number && (
                          <FormErrorMessage>
                            {errors.name.crm_number}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl isInvalid={errors.crm_uf}>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          CRM UF
                        </FormLabel>
                        <Controller
                          name="crm_uf"
                          {...register("crm_uf")}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <ReactSelect
                              name="crm_uf"
                              options={selectStates}
                              isClearable
                              backspaceRemovesValue={true}
                              placeholder="Selecione"
                              onBlur={onBlur}
                              onChange={onChange}
                              selected={value}
                              menuPlacement={"top"}
                              styles={
                                colorMode === "light"
                                  ? msLightTheme
                                  : msDarkTheme
                              }
                            />
                          )}
                        />
                        {errors.crm_uf && (
                          <FormErrorMessage>
                            {errors.crm_uf.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl isInvalid={errors.phone}>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Telefone
                        </FormLabel>
                        <Input
                          {...register('phone')} 
                          as={InputMask}
                          mask="(99) 99999-9999"
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                        {errors.phone && (
                          <FormErrorMessage>
                            {errors.phone.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                      <FormControl isInvalid={errors.speciality}>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          Especialidade
                        </FormLabel>
                        <Input
                          {...register("speciality")}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                        {errors.speciality && (
                          <FormErrorMessage>
                            {errors.speciality.message}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                <Flex mt="24px" align={"center"} justify={"flex-end"}>
                  <NavLink to="/sistema/administracao/usuarios">
                    <Text
                      mr="20px"
                      fontSize="xs"
                      color={textColor}
                      fontWeight="bold"
                    >
                      Cancelar
                    </Text>
                  </NavLink>
                  <Button
                    isLoading={isLoading}
                    type="submit"
                    variant="no-hover"
                    bg={buttonBg}
                    alignSelf="flex-end"
                    w="100px"
                    h="35px"
                  >
                    <Text fontSize="xs" color="#fff" fontWeight="bold">
                      Criar
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </form>
          </FormProvider>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CreateUser;
