import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import AppProvider from 'providers/appProvider'

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import PrivateRoute from "components/PrivateRoute/PrivateRoute";

ReactDOM.render(
    <AppProvider>
      <BrowserRouter>
        <Switch>
          <Route path={`/login/:path/:email/:hash`} component={AuthLayout} />
          <Route path={`/login/:path`} component={AuthLayout} />
          <Route path={`/login`} component={AuthLayout} />
          <PrivateRoute path={`/sistema`} component={AdminLayout} />
          <Redirect from={`/`} to="/sistema/dashboard" />
        </Switch>
      </BrowserRouter>
    </AppProvider>,
  document.getElementById("root")
);
