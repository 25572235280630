import LaboratoriesFilteredProvider from "providers/laboratoriesFilteredProvider";
import CompaniesFilteredProvider from "providers/companiesFilteredProvider";
import DemographicFilteredProvider from "providers/demographicFilteredProvider";
import EpidemicFilteredProvider from "providers/epidemicFilteredProvider";

import EpidemiologicalDashboard from "views/Dashboards/EpidemiologicalDashboard";
import DemographicDashboard from "views/Dashboards/DemographicDashboard";
import LaboratoriesDashboard from "views/Dashboards/LaboratoriesDashboard";
import CompaniesDashboard from "views/Dashboards/CompaniesDashboard";
import LifeHabitsDashboard from "views/Dashboards/LifeHabitsDashboard";
import RisksDashboard from "views/Dashboards/RisksDashboard";

import DashboardProvider from "providers/dashboardProvider";
import OrderProvider from "providers/orderProvider";
import ReverseProvider from "providers/reverseProvider";
import UserProvider from "providers/userProvider";
import UnitProvider from "providers/unitProvider";
import TicketProvider from "providers/ticketProvider";

import KitOrders from "views/Orders/KitOrders";
import CreateKitOrder from "views/Orders/CreateKitOrder/CreateKitOrder";
import ViewKitOrder from "views/Orders/ViewKitOrder/ViewKitOrder";
import EditKitOrder from "views/Orders/EditKitOrder/EditKitOrder";

import Reverses from "views/Reverses/Reverses";
import CreateReverse from "views/Reverses/CreateReverse/CreateReverse";
import ViewReverse from "views/Reverses/ViewReverse/ViewReverse";
import EditReverse from "views/Reverses/EditReverse/EditReverse";

import Users from "views/Users/Users";
import CreateUser from "views/Users/CreateUser/CreateUser";
import ViewUser from "views/Users/ViewUser/ViewUser";
import EditUser from "views/Users/EditUser/EditUser";

import Units from "views/Units/Units";
import CreateUnit from "views/Units/CreateUnit/CreateUnit";
import ViewUnit from "views/Units/ViewUnit/ViewUnit";
import EditUnit from "views/Units/EditUnit/EditUnit";

import Tickets from "views/Tickets/Tickets";
import CreateTicket from "views/Tickets/CreateTicket/CreateTicket";
import ViewTicket from "views/Tickets/ViewTicket/ViewTicket";
import EditTicket from "views/Tickets/EditTicket/EditTicket";

import Profile from "views/Profile/Profile";
import Clients from "views/Clients/Clients";
import roles from "variables/roles";
import ClientProvider from "./providers/clientProvider";
import ViewClient from "./views/Clients/ViewClient/ViewClient";

const allRoles = [roles.super, roles.admin, roles.partner, roles.client, roles.doctor];
const sapcRoles = [roles.super, roles.admin, roles.partner, roles.client];
const sapRoles = [roles.super, roles.admin, roles.partner];

const routes = [{
    name: "dashboards",
    path: "/dashboard/laboratorios",
    component: LaboratoriesDashboard,
    provider: LaboratoriesFilteredProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "dashboards",
    path: "/dashboard/empresas",
    component: CompaniesDashboard,
    provider: CompaniesFilteredProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "dashboards",
    path: "/dashboard/demografico",
    component: DemographicDashboard,
    provider: DemographicFilteredProvider,
    layout: "/sistema",
    roles: sapcRoles,
}, {
    name: "dashboards",
    path: "/dashboard/epidemiologico",
    component: EpidemiologicalDashboard,
    provider: EpidemicFilteredProvider,
    layout: "/sistema",
    roles: sapcRoles,
}, {
    name: "dashboards",
    path: "/dashboard/habitos",
    component: LifeHabitsDashboard,
    provider: DashboardProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "dashboards",
    path: "/dashboard/riscos-fatores",
    component: RisksDashboard,
    provider: DashboardProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "solicitacoes",
    path: "/solicitacoes/kits",
    component: KitOrders,
    provider: OrderProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "solicitacoes",
    path: "/solicitacoes/kits/criar",
    component: CreateKitOrder,
    provider: OrderProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "solicitacoes",
    path: "/solicitacoes/kits/:id",
    component: ViewKitOrder,
    provider: OrderProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "solicitacoes",
    path: "/solicitacoes/kits/:id/editar",
    component: EditKitOrder,
    provider: OrderProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "solicitacoes",
    path: "/solicitacoes/coletas",
    component: Reverses,
    provider: ReverseProvider,
    layout: "/sistema",
    roles: sapcRoles,
}, {
    name: "solicitacoes",
    path: "/solicitacoes/coletas/criar",
    component: CreateReverse,
    provider: ReverseProvider,
    layout: "/sistema",
    roles: sapcRoles,
}, {
    name: "solicitacoes",
    path: "/solicitacoes/coletas/:id",
    component: ViewReverse,
    provider: ReverseProvider,
    layout: "/sistema",
    roles: sapcRoles,
}, {
    name: "solicitacoes",
    path: "/solicitacoes/coletas/:id/editar",
    component: EditReverse,
    provider: ReverseProvider,
    layout: "/sistema",
    roles: sapcRoles,
}, {
    name: "usuarios",
    path: "/administracao/usuarios",
    component: Users,
    provider: UserProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "usuarios",
    path: "/administracao/usuarios/criar",
    component: CreateUser,
    provider: UserProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "usuarios",
    path: "/administracao/usuarios/:id",
    component: ViewUser,
    provider: UserProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "usuarios",
    path: "/administracao/usuarios/:id/editar",
    component: EditUser,
    provider: UserProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "unidades",
    path: "/administracao/unidades",
    component: Units,
    provider: UnitProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "unidades",
    path: "/administracao/unidades/criar",
    component: CreateUnit,
    provider: UnitProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "usuarios",
    path: "/administracao/unidades/:id",
    component: ViewUnit,
    provider: UnitProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "usuarios",
    path: "/administracao/unidades/:id/editar",
    component: EditUnit,
    provider: UnitProvider,
    layout: "/sistema",
    roles: sapRoles,
}, {
    name: "tickets",
    path: "/suporte",
    component: Tickets,
    provider: TicketProvider,
    layout: "/sistema",
    roles: allRoles,
}, {
    name: "tickets",
    path: "/suporte/criar",
    component: CreateTicket,
    provider: TicketProvider,
    layout: "/sistema",
    roles: allRoles,
}, {
    name: "tickets",
    path: "/suporte/:id",
    component: ViewTicket,
    provider: TicketProvider,
    layout: "/sistema",
    roles: allRoles,
}, {
    name: "tickets",
    path: "/suporte/:id/editar",
    component: EditTicket,
    provider: TicketProvider,
    layout: "/sistema",
    roles: allRoles,
}, {
    name: "perfil",
    path: "/perfil",
    component: Profile,
    provider: UserProvider,
    layout: "/sistema",
},
    {
        name: "clientes",
        path: "/clientes",
        component: Clients,
        provider: ClientProvider,
        layout: "/sistema",
    },
    {
        name: "cliente",
        path: "/cliente/:id",
        component: ViewClient,
        provider: ClientProvider,
        layout: "/sistema",
    },

];

export default routes;
