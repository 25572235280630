import React from 'react'
import { useForm, Controller } from "react-hook-form";
import InputMask from 'react-input-mask';

import { useUnits } from 'hooks/units'

import { Box, Button, Collapse, Flex, FormControl, FormLabel, Grid, Input, Select, Skeleton, Text, 
  useColorModeValue, useToast } 
from "@chakra-ui/react";

import regions from 'variables/regions';
import states from "variables/states"
import cities from "variables/cities"

import Icon from 'components/FontAwesomeIcons/Icon';
import { NEW_GENERAL_REPORT } from 'services/report';


const FiltersBox = ({
  setFilters, 
  regionsFilter = false,
  statesFilter = false,
  citiesFilter = false,
  kitsFilter = false,
  unitsFilter = false,
  dateFilter = false, 
  reportDownload = false}) => {

  const { isFetching, units, type_kits } = useUnits()
  const { register, getValues, setValue } = useForm();
  const toast = useToast()
  const [expand, setExpand] = React.useState((window.screen.width <= 766) ? false : true);
  const [state, setState] = React.useState(null);
  const [citiesFil, setCitiesFil] = React.useState(cities);

  React.useEffect(() => {
    if(state) setCitiesFil(cities.filter(city => city.state_id == state));
    else setCitiesFil(cities);
  }, [state])
  const generateNewReport = () => {
    NEW_GENERAL_REPORT(getValues());
    toast({
      title: 'Arquivo solicitado com sucesso.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }
  const applyFilters = () => {
    const filters = []

    regionsFilter && getValues('region') && filters.push(`region_id=${getValues('region')}`);
    statesFilter && getValues('state') && filters.push(`state_id=${getValues('state')}`);
    citiesFilter && getValues('city') && filters.push(`city_id=${getValues('city')}`);
    kitsFilter && getValues('kit') && filters.push(`kit_id=${getValues('kit')}`);
    unitsFilter && getValues('unit') && filters.push(`unit_id=${getValues('unit')}`);
    dateFilter && getValues('start_date') !== '__/__/____' && getValues('start_date') !== '' && filters.push(`start_date=${getValues('start_date')}`);
    dateFilter && getValues('end_date') !== '__/__/____' && getValues('end_date') !== '' && filters.push(`end_date=${getValues('end_date')}`);

    setFilters(filters)
  }

  const clearFilters = () => {
    setValue('region', null);
    setValue('state', null);
    setValue('city', null);
    setValue('kit', null);
    setValue('unit', null);
    setValue('start_date', '__/__/____');
    setValue('end_date', '__/__/____');
    applyFilters();
  }

  const inputFocus = useColorModeValue("healthlab.800", "healthlab.800");
  const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
  const buttonBgHover = useColorModeValue("healthlab.800", "healthlab.800");

  return (
    <Box 
      w={"100%"}
      pl={22}
      pr={22}
      pb={22}
    >
      { !expand &&
        <Box position="absolute" top="0" right="0" pt="22px">
          <Button colorScheme={"gray"} size={"sm"} mr={6} onClick={() => setExpand(!expand)}>
            { expand ? <Icon icon="shrink" color="white" /> : <Icon icon="expand" color="white" /> }
          </Button>
        </Box>
      }
        <Collapse in={expand} animateOpacity>
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }}
            templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
            gap="20px"
          >
            <FormControl hidden={!regionsFilter}>
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Filtrar por região
              </FormLabel>
              <Select
                {...register('region')} 
                borderRadius="8px"
                fontSize="md"
                placeholder="Selecione uma opção..."
                color="gray.400"
                focusBorderColor={inputFocus}
              >
                {
                  regions.map(region => {
                    return (
                      <option id={region.id} value={region.id}>{region.name}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
            <FormControl hidden={!statesFilter}>
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Filtrar por estado
              </FormLabel>
              <Select
                {...register('state')} 
                borderRadius="8px"
                fontSize="md"
                placeholder="Selecione uma opção..."
                color="gray.400"
                focusBorderColor={inputFocus}
                onChange={({target}) => setState(target.value)}
              >
                {
                  states.map(state => {
                    return (
                      <option id={state.id} value={state.id}>{state.name}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
            <FormControl hidden={!citiesFilter}>
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Filtrar por cidade
              </FormLabel>
              <Select
                {...register('city')} 
                borderRadius="8px"
                fontSize="md"
                placeholder="Selecione uma opção..."
                color="gray.400"
                focusBorderColor={inputFocus}
              >
                {
                  citiesFil.map(city => {
                    return (
                      <option id={city.id} value={city.id}>{city.name}</option>
                    )
                  })
                }
              </Select>
            </FormControl>
            <FormControl hidden={!kitsFilter}>
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Filtrar por check-up
              </FormLabel>
              
              <Skeleton height={"40px"} isLoaded={!isFetching}>
                <Select
                  {...register('kit')} 
                  borderRadius="8px"
                  fontSize="md"
                  placeholder="Selecione uma opção..."
                  color="gray.400"
                  focusBorderColor={inputFocus}
                >
                  {
                    type_kits.map(tk => (
                        <option value={tk.id}>{tk.name}</option>
                    ))
                  }
                </Select>
              </Skeleton>
            </FormControl>
            <FormControl hidden={!unitsFilter}>
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Filtrar por unidade
              </FormLabel>
              <Skeleton height={"40px"} isLoaded={!isFetching}>
                <Select
                  {...register('unit')} 
                  borderRadius="8px"
                  fontSize="md"
                  placeholder="Selecione uma opção..."
                  color="gray.400"
                  focusBorderColor={inputFocus}
                >
                  {
                    units.map(unit => {
                      return (
                        <option key={unit.id} value={unit.id}>{unit.name}</option>
                      )
                    })
                  }
                </Select>
              </Skeleton>
            </FormControl>
            <FormControl hidden={!dateFilter}>
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Data de início
              </FormLabel>
              <Input
                {...register('start_date')} 
                as={InputMask} 
                mask="99/99/9999"
                placeholder="dd/mm/aaaa"
                borderRadius="8px"
                fontSize="md"
                focusBorderColor={inputFocus}
              />
            </FormControl>
            <FormControl hidden={!dateFilter}>
              <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                Data de fim
              </FormLabel>
              <Input
                {...register('end_date')} 
                as={InputMask} 
                mask="99/99/9999"
                placeholder="dd/mm/aaaa"
                borderRadius="8px"
                fontSize="md"
                focusBorderColor={inputFocus}
              />
            </FormControl>
          </Grid>
        </Collapse>
      { (expand ) &&
        <Box>
          <Flex
            w={"100%"}
            justify={'flex-end'}
            mt="20px"
          >
            <Button colorScheme={"gray"} size={"sm"} mr={6} onClick={() => setExpand(!expand)}>
              { expand ? <Icon icon="shrink" color="white" /> : <Icon icon="expand" color="white" /> }
            </Button>
            <Button colorScheme={"orange"} size={"sm"} mr={6} onClick={() => clearFilters()}>
              Limpar filtros
            </Button>
            <Button color="#FFF" bg={buttonBg} variant="no-hover" size={"sm"} onClick={() => applyFilters()}>
              Aplicar filtros
            </Button>
            {reportDownload &&
              <Button color="#FFF" bg={buttonBg} variant="no-hover" size={"sm"} style={{marginLeft: "20px"}} onClick={() => generateNewReport()}>
                Exportar relatório geral
              </Button>
            }
          </Flex>
        </Box>
         }

    </Box>
  )
}

export default FiltersBox
