import React from "react";
import { NavLink, useParams } from "react-router-dom";

import { useUser } from "hooks/users";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import states from "variables/states";

const ViewUser = () => {
  const { id } = useParams();
  const { isFetching: isLoading, data } = useUser(id);

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w={{ md: "100%", lg: "50%" }}>
          <CardHeader mb="40px">
            <Flex direction="row" w="100%" justify="space-between">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Usuário #{id}
              </Text>
              <NavLink to="/sistema/administracao/usuarios">
                <Button >
                  Voltar
                </Button>
              </NavLink>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction="column" w="100%">
              <Grid
                templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                templateRows={{ md: "repeat(1, 1fr)" }}
                gap="24px"
              >
                <FormControl >
                  <FormLabel
                    color={textColor}
                    fontWeight="bold"
                    fontSize="xs"
                  >
                    Nome
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!isLoading}>
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mb="3px"
                    >
                      {data?.name || null}
                    </Text>
                  </Skeleton>
                </FormControl>
                <FormControl >
                  <FormLabel
                    color={textColor}
                    fontWeight="bold"
                    fontSize="xs"
                  >
                    E-mail
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!isLoading}>
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mb="3px"
                    >
                      { data?.email || null }
                    </Text>
                  </Skeleton>
                </FormControl>
              </Grid>
              <Grid
                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                templateRows={{ md: "repeat(1, 1fr)" }}
                gap="24px"
                mt="24px"
              >
                <FormControl >
                  <FormLabel
                    color={textColor}
                    fontWeight="bold"
                    fontSize="xs"
                  >
                    Unidade
                  </FormLabel>
                  <Skeleton height={"25px"} isLoaded={!isLoading}>
                    <Flex display={"flex"} w="100%">
                      {data && data.units ? 
                        data.units.map((unit, x) => (
                          <Text
                            color={textColor}
                            fontSize="lg"
                            fontWeight="bold"
                            mb="3px"
                            mr="5px"
                          >
                            {unit.name}{(x+1) < data.units.length && ', '}
                          </Text> 
                        )) : null}
                    </Flex>
                  </Skeleton>
                </FormControl>
              </Grid>
              {
                data?.role == "Médico" &&
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                  templateRows={{ md: "repeat(1, 1fr)" }}
                  gap="24px"
                  mt="24px"
                >
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      CRM
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        { data?.doctor?.crm_number || null }
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      CRM UF
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        { data?.doctor?.crm_uf ? states.find(state => state.id == data.doctor.crm_uf).initials : null }
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Telefone
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        { data?.doctor?.phone || null }
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Especialidade
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        { data?.doctor?.speciality || null }
                      </Text>
                    </Skeleton>
                  </FormControl>
                </Grid>
              }
            </Flex>
          </CardBody>
        </Card>
      </Flex>
  )
}

export default ViewUser