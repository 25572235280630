import React, {useEffect} from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Controller, useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import { default as ReactSelect } from 'react-select';
import InputMask from 'react-input-mask';

import { useUser, useUpdateUser } from "hooks/users";
import { useUnits } from "../../../hooks/units";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Skeleton,
  Input,
  Select,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import roles from "variables/roles";
import states from "variables/states";

import { msLightTheme, msDarkTheme } from "variables/multiselectStyle";

const schema = yup.object().shape({
    name: yup
      .string()
      .required("O campo Nome é obrigatório"),
    email: yup
			.string()
			.email("Insira um e-mail válido")
			.required("O campo E-mail é obrigatório"),
    unit: yup
      .array()
      .required("O campo Unidade é obrigatório"),
    role: yup
      .string()
      .required('O campo "Perfil" é obrigatório'),
})

const EditUser = () => {
  const { id } = useParams();
  const { isFetching: isLoading, data } = useUser(id);
  const { units } = useUnits();
  const history = useHistory();
  const { colorMode } = useColorMode();

  const selectRoles = [
    { value: roles.doctor, label: "Médico" },
    { value: roles.partner, label: "Usuário" },
  ];
  const selectStates = states.map((i) => ({ value: i.id, label: i.initials }));

  const methods = useForm();
  const toast = useToast()

  const { control, register, reset, handleSubmit, formState: { errors }, watch } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({
      unit: data?.units?.map((unit) => ({value: unit.id, label: unit.name}))
    })
  }, [data])


  const { mutateAsync: user, isLoading: isLoadingUpdate  } = useUpdateUser(id, {
    onSuccess: () => {
      toast({
        title: 'Usuário atualizado com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      history.push(`/sistema/administracao/usuarios/${id}`);
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })
  
  const watchRole = watch("role", data?.role ? data?.role : null); // escuta o campo role e as mudanças dele
  const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
  const inputHover = useColorModeValue("healthlab.800", "healthlab.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w={{ md: "100%", lg: "50%" }}>
          <CardHeader mb="40px">
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Editar Usuário #{id}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <FormProvider {...methods}>
              <form style={{width: '100%'}} onSubmit={(handleSubmit(user))}>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                    gap="24px"
                  >
                    <FormControl isInvalid={errors.role}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Perfil
                      </FormLabel>
                      
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Select
                          {...register('role')} 
                          defaultValue={data?.role || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                          placeholder="Selecione uma opção..."
                        >
                          {
                            selectRoles.map(role => {
                              return (
                                <option id={role.value} value={role.value}>{role.label}</option>
                              )
                            })
                          }
                        </Select>
                      }
                      {errors.role && (
                        <FormErrorMessage>{errors.role.message}</FormErrorMessage>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                    gap="24px"
                    mt="24px"
                  >
                    <FormControl isInvalid={errors.name}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Nome
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('name')} 
                          defaultValue={data?.name || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.email}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        E-mail
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('email')} 
                          defaultValue={data?.email || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
                    </FormControl>
                  </Grid>
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                    gap="24px"
                    mt="24px"
                  >
                    <FormControl isInvalid={errors.unit}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Unidade
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Controller
                        {...register('unit') }
                        name='unit'
                        control = { control }
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <ReactSelect
                            options = {units.map(i => ({value:i.id, label: i.name}))}
                            isSearchable
                            isClearable
                            isMulti
                            backspaceRemovesValue = { true }
                            placeholder = "Selecione"
                            onBlur = { onBlur }
                            onChange={ onChange }
                            selected={ value }
                            defaultValue = { data?.units?.map((unit) => ({value: unit.id, label: unit.name})) }
                            styles={colorMode == 'light' ? msLightTheme : msDarkTheme}
                            />
                        )}
                        />
                      }
                      {errors.unit && <FormErrorMessage>{errors.unit.message}</FormErrorMessage>}
                    </FormControl>
                  </Grid>
                  {
                    ((!watchRole && data?.role == roles.doctor) ||
                    (watchRole === roles.doctor)) && (
                      <Grid
                        templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                        gap="24px"
                        mt="24px"
                      >
                        <FormControl isInvalid={errors.crm_number}>
                          <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                          >
                            CRM Número
                          </FormLabel>
                          
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                          <Input
                            {...register("crm_number")}
                            defaultValue={data?.doctor?.crm_number || null}
                            focusBorderColor={inputHover}
                            type="number"
                            borderRadius="8px"
                            fontSize="md"
                          />
                      }
                          {errors.crm_number && (
                            <FormErrorMessage>
                              {errors.name.crm_number}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        <FormControl isInvalid={errors.crm_uf}>
                          <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                          >
                            CRM UF
                          </FormLabel>
                          {
                            isLoading ?
                            <Skeleton height={"40px"} />
                            :
                            <Select
                              {...register('crm_uf')} 
                              defaultValue={data?.doctor?.crm_uf || null}
                              focusBorderColor={inputHover}
                              borderRadius="8px"
                              fontSize="md"
                              placeholder="Selecione uma opção..."
                            >
                              {
                                selectStates.map(state => {
                                  return (
                                    <option id={state.value} value={state.value}>{state.label}</option>
                                  )
                                })
                              }
                            </Select>
                          }
                          {errors.crm_uf && (
                            <FormErrorMessage>
                              {errors.crm_uf.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        <FormControl isInvalid={errors.phone}>
                          <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                          >
                            Telefone
                          </FormLabel>
                          {
                          isLoading ?
                          <Skeleton height={"40px"} />
                          :
                            <Input
                              {...register('phone')} 
                              defaultValue={data?.doctor?.phone || null}
                              as={InputMask}
                              mask="(99) 99999-9999"
                              focusBorderColor={inputHover}
                              borderRadius="8px"
                              fontSize="md"
                            />
                          }
                          {errors.phone && (
                            <FormErrorMessage>
                              {errors.phone.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                        <FormControl isInvalid={errors.speciality}>
                          <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                          >
                            Especialidade
                          </FormLabel>
                          {
                          isLoading ?
                          <Skeleton height={"40px"} />
                          :
                            <Input
                              {...register("speciality")}
                              defaultValue={data?.doctor?.speciality || null}
                              focusBorderColor={inputHover}
                              borderRadius="8px"
                              fontSize="md"
                            />
                          }
                          {errors.speciality && (
                            <FormErrorMessage>
                              {errors.speciality.message}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Grid>
                    )}
                  <Flex mt="24px" align={"center"} justify={"flex-end"}>
                    <NavLink to="/sistema/administracao/usuarios">
                      <Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
                        Cancelar
                      </Text>
                    </NavLink>
                    <Button
                      isLoading={isLoadingUpdate}
                      type='submit'
                      variant="no-hover"
                      bg={buttonBg}
                      alignSelf="flex-end"
                      w="100px"
                      h="35px"
                    >
                      <Text fontSize="xs" color="#fff" fontWeight="bold">
                        Atualizar
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </FormProvider>
          </CardBody>
        </Card>
      </Flex>
  );
}

export default EditUser;
