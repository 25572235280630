
import { useAuth, useLogout, useLogout_ } from "hooks/auth";

// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import menuItems from "menu"
import {  } from 'hooks/auth'
import Icon from "components/FontAwesomeIcons/Icon";
import {useNotifications} from "hooks/notifications"
import TimeAgo from 'javascript-time-ago'
import br from 'javascript-time-ago/locale/br.json'
import ReactTimeAgo from 'react-time-ago'
import { DOWNLOAD_NOTIFICATION } from "services/notifications";
import { IoDocumentsSharp } from "react-icons/io5";

TimeAgo.addDefaultLocale(br)
TimeAgo.addLocale(br)

export default function HeaderLinks(props) {
  const data = useAuth();
  const user = data?.user?.data?.name || data?.user?.name || '';
  const { notifications } = useNotifications()
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  const toast = useToast()

  const [newNot, setNewNot] = React.useState(notifications);
  const [hasNewNot, setHasNewNot] = React.useState(false);

  React.useEffect(() => {
    if(notifications.length > newNot.length){
      toast({
        title: 'Arquivo recebido com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      })
      setNewNot(notifications)
      setHasNewNot(true);
    }
  }, [notifications])

  const downloadNotification = async (nId) => {
    try {
      const link = await DOWNLOAD_NOTIFICATION(nId);
      window.open(link, 'testeee')
    } catch(e) {
      toast({
        title: 'Link para download expirado, solicite um novo relatório.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  // Chakra Color Mode
  let mainhealthlab = useColorModeValue("healthlab.900", "healthlab.900");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("white", "white");
  let searchIcon = useColorModeValue("gray.700", "gray.200");


  const logout = () => {
    useLogout_()
  }

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
    >
      <NavLink to="/sistema/perfil">
        <Button
          ms="0px"
          px="0px"
          color={navbarIcon}
          variant="transparent-with-icon"
          leftIcon={
            <ProfileIcon color={navbarIcon} w="22px" h="22px" me="0px" />
          }
        >
          <Text fontWeight="bold" color={navbarIcon} cursor="pointer" mr="16px" display={{ sm: "none", md: "flex" }}>{user}</Text>
        </Button>
      </NavLink>
      <Text fontWeight="bold" color={navbarIcon} cursor="pointer" mr="16px" display={{ sm: "none", md: "flex" }} onClick={() => logout()}>
        <Icon icon="logout" margin="6" />
        Sair
      </Text>
      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={menuItems}
        {...rest}
      />
      <SettingsIcon
        cursor="pointer"
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      />
      <Menu>
        <MenuButton onClick={() => setHasNewNot(false)}>
          <BellIcon color={hasNewNot ? 'red.400' : navbarIcon} w="18px" h="18px" />
        </MenuButton>
        <MenuList p="16px 8px">
          <Flex flexDirection="column">
            {notifications
              .map(i => (
                  <MenuItem borderRadius="8px" mb="10px" key={i.id} onClick={() => downloadNotification(i.id)}>
                    <ItemContent
                        bg='healthlab.900'
                        icon={<IoDocumentsSharp fontSize='1.5rem' />}
                        time={<ReactTimeAgo date={i.created_at} locale="br" timeStyle="twitter"/>}
                        info={i.message}
                        boldInfo={i.title}
                    />
                  </MenuItem>
              ))
            }

            {/*<MenuItem borderRadius="8px">*/}
            {/*  <ItemContent*/}
            {/*    time="3 days ago"*/}
            {/*    info="Payment succesfully completed!"*/}
            {/*    boldInfo=""*/}
            {/*    aName="Kara"*/}
            {/*    aSrc={avatar3}*/}
            {/*  />*/}
            {/*</MenuItem>*/}
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
